<template>
	<a-modal title="复制参数名" :width="500" :visible="visible" :confirmLoading="confirmLoading" @ok="handleSubmit" :height="700"
		@cancel="handleCancel">
		<a-spin :spinning="confirmLoading">
			<a-row :gutter="24">
				<a-col :md="24" :sm="24">
					<a-form :form="form">
						<a-form-item label="复制到" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
							<a-tree-select style="width: 100%" :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
								placeholder="请选择" allow-clear tree-default-expand-all @change="selectGoodsCategory"
								v-decorator="['goodsCategoryId', { rules: [{ required: true, message: '请选择!' }] }]">
								<a-tree-select-node v-for="parentItem in classList" :key="parentItem.id"
									:value="parentItem.id" :title="parentItem.name" :selectable="false">
									<a-tree-select-node v-for="item in parentItem.childrenList" :key="item.id"
										:selectable="true" :value="item.id" :title="item.name" />
								</a-tree-select-node>
							</a-tree-select>
						</a-form-item>
					</a-form>
				</a-col>
			</a-row>
		</a-spin>
	</a-modal>
</template>

<script>
	import {
		copyParamsName
	} from "@/api/modular/mallLiving/paramsName/paramsName";
	import {
		allPageList
	} from '@/api/modular/mallLiving/commodity/commodityClass'
	export default {
		data() {
			return {
				labelCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 6
					},
				},
				wrapperCol: {
					xs: {
						span: 24
					},
					sm: {
						span: 16
					},
				},

				visible: false,
				confirmLoading: false,
				form: this.$form.createForm(this),
				id: '',
				specId: '',
				classList: [],
				copyCategoryId: '',
				categoryId: ''
			}
		},
		methods: {
			// 初始化方法
			edit(record) {
				this.categoryId = record
				this.findAllCategoryList()
				this.visible = true
			},
			
			findAllCategoryList(){
				allPageList().then((res) => {
					//处理分类数据
					this.classList = res.data
				})
			},
			selectGoodsCategory(e){
				console.log(e)
			},
			handleSubmit() {
				const {
					form: {
						validateFields
					},
				} = this
				this.confirmLoading = true
				validateFields((errors, values) => {
					if (!errors) {
						let params = {
							sourceCategoryId: this.categoryId,
							targetCategoryId: values.goodsCategoryId
						}
						copyParamsName(params).then(res => {
							if (res.success) {
								this.$message.success('参数名复制成功')
								this.confirmLoading = false
								this.$emit('ok', values)
								this.handleCancel()
							}
						}).finally((res) => {
							this.confirmLoading = false
						})
					} else {
						this.confirmLoading = false
					}
				})
			},
			handleCancel() {
				this.form.resetFields()
				this.visible = false
			},
		},
	}
</script>
<style>
	/* you can make up upload button and sample style by using stylesheets */
	.ant-upload-select-picture-card i {
		font-size: 32px;
		color: #999;
	}

	.ant-upload-select-picture-card .ant-upload-text {
		margin-top: 8px;
		color: #666;
	}
</style>
